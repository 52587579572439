import React, { useEffect } from "react"

const CancelPayment = () => {
  useEffect(() => {
    setTimeout(() => {
      window.location.replace("/")
    }, 2500)
  }, [])

  return <div></div>
}

export default CancelPayment
